<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Badges for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceBadges" />
      </v-card-title>

      <v-card-text>      
        Create and manage the badges for this leaderboard.
        <a v-if="tenant.isDefault" href="https://corporatefitness.helpscoutdocs.com/category/10-badges" target="_blank"><i class="fadl fa fa-books"/> Documentation</a>
      </v-card-text>

      <v-card-text v-if="race.scoring == 'BADGES'">      
        Badges marked with a <v-icon small color="green">fa-asterisk</v-icon> are part of the challenge goal.
      </v-card-text>
      
      <v-alert v-if="race.scoring == 'BADGES' && badges && !badges.some(x => x.include_challenge)" type="warning" tile>
        Please mark at least one badge to be included in the challenge goal.
      </v-alert>
      
      
      <v-alert v-if="readonly" type="info" tile>
        Badges are in read-only mode for this leaderboard.
      </v-alert>
      
      <v-data-table
        :headers="headers"
        :items="badges"
        :items-per-page="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Badges</v-toolbar-title>
            <v-spacer/>
            <v-btn v-if="race.route && eventUtil.hasRole('FULL_ACCESS')" color="accent" outlined class="mr-2" @click="showBadgeAlongCourseDialog">
              <v-icon class="mr-2">fa-route</v-icon>
              Add along course
            </v-btn>
            <!-- <v-btn v-if="race.badge_scoring === 'MANUAL_CLAIM'" color="accent" outlined class="mr-2" @click="$refs.claimBadges.open">
              <v-icon class="mr-2">fa-shield</v-icon>
              Manage Achievements
            </v-btn> -->
            <v-dialog v-model="copyFromDialog" scrollable max-width="400px">
              <template v-slot:activator="{ on }">
                <v-btn v-if="eventUtil.hasRole('FULL_ACCESS')" :disabled="readonly" color="accent" outlined class="mr-2" v-on="on">
                  <v-icon class="mr-2">fa-clone</v-icon>
                  Copy
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Select which race to copy from</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                  <v-radio-group v-model="copyFromRaceId" column>
                    <v-radio v-for="(item, idx) in otherRacesInEvent" :key="idx" :label="item.name" :value="item.id"></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="blue secondary" text @click="copyFromDialog = false">Close</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="!copyFromRaceId" color="blue secondary" @click="copyBadges" :loading="$store.getters.isLoading">Copy</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>            
            
            <v-dialog v-if="race.badge_scoring==='MANUAL_CLAIM'" v-model="showAddAchievementDialog" max-width="650px" @input="v => v || closeDialog()">
              <template v-slot:activator="{ on }">
                <v-btn v-if="eventUtil.hasRole('FULL_ACCESS')" :disabled="readonly" color="accent" class="mr-2" v-on="on"><v-icon small class="mr-2">fa-plus</v-icon> New Achievement</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">Add an Achievement</v-card-title>
                <v-card-text>
                <v-form v-model="valid" lazy-validation ref="achievementForm">
                  <v-text-field v-model="editedItem.name" label="Achievement name" required autofocus :rules="nameRules"></v-text-field>

                  <v-textarea v-model="editedItem.description" rows="3" label="Description (optional)" ></v-textarea>

                  <DistanceTextArea
                    v-model.number="computedValue" 
                    :unit="event.unit"
                    :mode="$helpers.UnitType.NUMBER"
                    label="Points" 
                    hint="The points awarded for completing this achieving. More is better. Leave at 0 when all achievements have same weight."
                    />
                </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="showAddAchievementDialog=false">Cancel</v-btn>
                  <v-btn color="secondary" :loading="$store.getters.isLoading" @click="saveAchievementDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showDialog" max-width="650px" @input="v => v || closeDialog()">
              <template v-slot:activator="{ on }">
                <v-btn v-if="eventUtil.hasRole('FULL_ACCESS')" :disabled="readonly" color="accent" v-on="on"><v-icon small class="mr-2">fa-plus</v-icon> New Badge</v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">{{ formTitle }}</v-card-title>
                <v-form v-model="valid" lazy-validation ref="badgeForm">
                <v-alert v-if="!valid" type="warning" tile>There is a validation error. Please check all fields and re-try.</v-alert>
                <v-expansion-panels accordion tile class="mb-4" v-model="initialPanel">  
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
                      Badge {{editedItem.name}}
                      <v-spacer />
                      <v-icon v-if="!valid && !editedItem.name" small color="warning">fa-exclamation-triangle</v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field v-model="editedItem.name" label="Badge name" required :rules="nameRules"></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-window-restore</v-icon>
                      Popup Window
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p>Customize the badge popup window which can be used to provide additional information on this badge.</p>
                      <v-textarea v-model="editedItem.description" rows="3" label="Description (optional)" ></v-textarea>
                      <v-select
                        :items="siteData.badge_details_types"
                        v-model="editedItem.details_type" 
                        item-value="type"
                        item-text="text"
                        label="Type of popup"
                        hide-details
                        solo
                        full-width
                        class="mt-0 mb-4"
                      ></v-select>
                      <p v-if="tenant.isDefault" class="text-muted">
                        Please read our <a href="https://corporatefitness.helpscoutdocs.com/article/86-badge-details-popups" target="_blank"><i class="fadl fa fa-books"/> Knowledge Base article</a> on how to configure badge details to achieve best results.
                      </p>

                      <v-text-field 
                        v-if="editedItem.details_type == 'STREET_VIEW'" 
                        v-model="editedItem.details_pano_id" 
                        label="Google Street View Panorama ID" 
                        persistent-hint
                        hint="Leave empty to show the panorama as it would be on the location of this badge on the virtual course map."
                        />
                      <v-text-field 
                        v-if="editedItem.details_type == 'VIDEO'" 
                        v-model="editedItem.details_video" 
                        label="Youtube video link (or mp4 video) url" 
                        persistent-hint
                        hint="Enter the Youtube url (e.g. https://www.youtube.com/watch?v=TTAU7lLDZYU) or a link to a mp4 video."
                        />
                      <p v-if="!editedItem.id && (editedItem.details_type == 'IMAGE' || editedItem.details_type == 'PANORAMA')" class="text-muted">
                        Please save your badge first before you can upload a picture here.
                      </p>
                      <v-row v-if="editedItem.id && (editedItem.details_type == 'IMAGE' || editedItem.details_type == 'PANORAMA')" >
                        <v-col cols="6">
                          <h5>Upload Image</h5>
                          <v-file-input filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Select image to show in popup window" v-model="uploadFile" v-on:change="uploadBadgeDetailsImage"></v-file-input>
                          <p v-if="editedItem.details_type == 'PANORAMA'" class="text-muted mb-0">Image must be in equirectangular projection.</p>
                        </v-col>
                        <v-col v-if="editedItem.details_img" cols="6">
                          <h5>Preview</h5>
                          <img :src="editedItem.details_img" style="max-width:100%; max-height:200px;" />
                          <p v-if="editedItem.details_type == 'PANORAMA'" class="text-muted mb-0">It is normal for panoramas to show distorted in the preview.</p>
                        </v-col>
                      </v-row>
<!--                       <v-text-field 
                        v-if="editedItem.details_type == 'IMAGE'" 
                        v-model="editedItem.details_img" 
                        label="Url to image to show in popup window" 
                        />
                      <v-text-field 
                        v-if="editedItem.details_type == 'PANORAMA'" 
                        v-model="editedItem.details_img" 
                        label="Url to image to show in popup window" 
                        persistent-hint
                        hint="This should be an image in equirectangular projection"
                        />
 -->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-tools</v-icon>
                      Configuration
                      <v-spacer/>
                      <span v-if="editedItem.active" class="shrink mr-4 green--text">ACTIVE</span>
                      <span v-if="!editedItem.active" class="shrink mr-4 red--text">INACTIVE</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="4">
                        <v-switch v-model="editedItem.active" class="mt-0" label="Active" hint="" persistent-hint></v-switch>
                        <v-switch v-if="race.collective" v-model="editedItem.collective" class="mt-0" label="Based on Collective Progress" hint="" persistent-hint></v-switch>
                        <v-switch v-model="editedItem.show_map" class="mt-0" label="Show on Map" hint="" persistent-hint></v-switch>
                        <v-switch v-model="editedItem.post_feed" class="mt-0" label="Post to Feed" hint="" persistent-hint></v-switch>
                        <v-switch v-model="editedItem.hide_until_rewarded" class="mt-0" label="Hide until rewarded" hint="When enabled, this badge won't be shown on leaderboard page" persistent-hint></v-switch>
                      </v-col>
                      <v-col cols="8" v-if="editedItem.active">
                        <p class="text-muted">Optional date restriction. Only set when different from event/leaderboard dates:</p>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <DateWithTimeZonePicker v-model="editedItem.from" :timeZone="event.timeZoneOlson" clearable label="Active from (included)" :minDate="race.from || event.from" :maxDate="race.till || event.till" class="pt-0 mt-0" />
                          </v-col>
                          <v-col cols="6">
                            <DateWithTimeZonePicker v-model="editedItem.till" :timeZone="event.timeZoneOlson" clearable label="Active till (included)" :minDate="race.from || event.from" :maxDate="race.till || event.till" class="pt-0 mt-0" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="editedItem.metric == 'COORDINATE' && race.scoring == 'TRACK' && betaMode" cols="12" sm="12" md="12" class="__py-0">
                        <v-text-field
                          v-model="editedItem.audio_msg" 
                          label="Audio Message" 
                          hint="Enter optional audio message to play when getting close to this location"
                          prepend-icon="fa-volume"
                          persistent-hint
                          />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="race.scoring == 'BADGES'" cols="6" class="py-0">
                        <v-switch v-model="editedItem.include_challenge" class="mt-0" label="Include in Challenge Goal" hide-details></v-switch>
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <v-select
                          :items="otherBadgesInRace"
                          v-model="editedItem.after_badge_id" 
                          item-value="id"
                          item-text="name"
                          label="Only After Badge"
                          hint="Use for multi-sport or ordered sequence of badges"
                          persistent-hint
                          class="mt-0"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="py-0 pt-4">
                        <h4>Bonus Score</h4>
                        <p>Give bonus points when this badge is awarded.</p>
                        <DistanceTextArea
                          v-if="race.scoring == 'TIME'" 
                          v-model.number="computedBonusValue" 
                          :unit="event.unit"
                          :mode="$helpers.UnitType.NUMBER"
                          :multiplier="3600"
                          label="Bonus (in hours)" 
                          />
                        <DistanceTextArea
                          v-else-if="race.scoring == 'DISTANCE'" 
                          v-model.number="computedBonusValue" 
                          :unit="event.unit"
                          :mode="$helpers.UnitType.DISTANCE"
                          :label="`Bonus (in ${event.unit == 'METRIC' ? 'km' : 'mi'})`" 
                          />
                        <v-text-field 
                          v-else
                          v-model.number="computedBonusValue" 
                          label="Bonus score" 
                          :hint="unitTypeDiplay"
                          persistent-hint
                          class="mt-0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-bullseye</v-icon>
                      Goal
                      <v-spacer/>
                      <span class="shrink mr-4">{{ siteData.badge_metrics.find(x => x.type == editedItem.metric).text }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p>Select the metric on which this badge should be based. Please read <a href="https://corporatefitness.helpscoutdocs.com/article/84-badges" target="_blank">the documentation</a> for tips and tricks.</p>
                      <v-select
                        :items="siteData.badge_metrics"
                        v-model="editedItem.metric" 
                        item-value="type"
                        item-text="text"
                        label="Metric"
                        :rules="selectRules"
                        hide-details
                        solo
                        full-width
                        class="mt-0 mb-4"
                      ></v-select>
                      <h3 v-if="showMetricUnit || showValue">Goal Configuration</h3>
                      <v-row>
                      <v-col v-if="showMetricUnit" cols="12" sm="4" md="4" class="py-0">
                        <v-select
                          :items="siteData.badge_units"
                          v-model="editedItem.unit" 
                          item-value="type"
                          item-text="text"
                          label="Unit type"
                          :rules="selectRules"
                          class="mt-0 "
                        ></v-select>
                      </v-col>
                      <v-col v-if="showValue" cols="12" sm="4" md="4" class="py-0">
                        <DistanceTextArea
                          v-if="editedItem.metric == 'DURATION' || (editedItem.metric == 'SCORE' && race.scoring == 'TIME')" 
                          v-model.number="computedValue" 
                          :unit="event.unit"
                          :mode="$helpers.UnitType.NUMBER"
                          :multiplier="3600"
                          label="Total duration (in hours)" 
                          >
                        </DistanceTextArea>
                        <v-text-field 
                          v-else
                          v-model.number="computedValue" 
                          :label="valueLabel" 
                          required 
                          class="mt-0"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" class="py-0">
                        <h4>Activity Filter</h4>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'BEST_ACTIVITY' || editedItem.metric == 'ACTIVITY' || editedItem.metric == 'RACE_RESULT' || editedItem.metric == 'AGGREGATION'" cols="12" sm="12" md="12" class="py-0">
                        <v-select
                          :items="siteData.badge_fields"
                          v-model="editedItem.activity_field" 
                          item-value="type"
                          item-text="text"
                          label="Field"
                          class="mt-0"
                        ></v-select>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'AGGREGATION'" cols="12" sm="12" md="12" class="py-0">
                        <v-select
                          :items="siteData.scoring_aggregation_types"
                          v-model="editedItem.activity_aggr" 
                          item-value="type"
                          item-text="text"
                        label="Aggregation Method"
                          class="mt-0"
                        ></v-select>
                        <v-text-field
                          v-if="editedItem.activity_aggr == 'FORMULA'"
                          v-model="editedItem.transformation_expr" 
                          label="Custom Formula" 
                          persistent-hint
                          hint="Advanced setting. Enter a custom transformation function."
                          class="mt-0"
                          />
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="3" md="3" class="py-0">
                        <v-select
                          :items="activitiesWithDefault"
                          v-model="editedItem.activity_type" 
                          item-value="type"
                          item-text="text"
                          label="Activity Type"
                          class="mt-0"
                        ></v-select>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'BEST_ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="3" md="3" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.activity_distance" 
                          :unit="event.unit"
                          :label="`Min distance (in ${unitTypeDiplay})`" 
                          >
                        </DistanceTextArea>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY'" cols="12" sm="3" md="3" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.activity_distance_min" 
                          :unit="event.unit"
                          :label="`Min distance (in ${unitTypeDiplay})`" 
                          >
                        </DistanceTextArea>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY'" cols="12" sm="3" md="3" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.activity_distance_max" 
                          :unit="event.unit"
                          :label="`Max distance (in ${unitTypeDiplay})`" 
                          >
                        </DistanceTextArea>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY' && editedItem.activity_time_min" cols="12" sm="6" md="6" class="py-0">
                        <DurationTextArea
                          v-model="editedItem.activity_time_min" 
                          dense
                          label="Min duration (optional)" 
                          >
                        </DurationTextArea>
                      </v-col>
                      <v-col v-else-if="editedItem.metric == 'ACTIVITY' || editedItem.metric == 'BEST_ACTIVITY' || editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="6" md="6" class="py-0">
                        <DurationTextArea
                          v-model="editedItem.activity_time" 
                          dense
                          :label="editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS' || editedItem.metric == 'BEST_ACTIVITY' || !editedItem.activity_distance ? `Min duration (optional)` : `Max duration (optional)`" 
                          >
                        </DurationTextArea>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY'" cols="12" sm="6" md="6" class="py-0">
                        <DurationTextArea
                          v-model="editedItem.activity_time_max" 
                          dense
                          label="Max duration (optional)" 
                          >
                        </DurationTextArea>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'ACTIVITY'" cols="12" sm="6" md="6" class="py-0">
                        <v-select
                          :items="providersWithDefault"
                          v-model="editedItem.activity_prov" 
                          item-value="type"
                          item-text="text"
                          label="Activity provider (data source)"
                          class="mt-0"
                        ></v-select>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="6" md="6" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.activity_steps" 
                          :mode="$helpers.UnitType.NUMBER"
                          label="Min # Steps" 
                          />
                      </v-col>
                      <v-col v-if="editedItem.metric == 'CONSECUTIVE_DAYS' || editedItem.metric == 'CONSECUTIVE_WEEKS'" cols="12" sm="6" md="6" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.activity_custom_min" 
                          :mode="$helpers.UnitType.NUMBER"
                          :label="`Min # ${race.custom}`" 
                          />
                      </v-col>
                      <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" class="py-0">
                        <h3>Coordinate</h3>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" sm="3" md="3" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.coord_lat" 
                          :mode="$helpers.UnitType.NUMBER"
                          label="Latitude" 
                          >
                        </DistanceTextArea>
                      </v-col>
                      <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" sm="3" md="3" class="py-0">
                        <DistanceTextArea
                          v-model="editedItem.coord_lng" 
                          :mode="$helpers.UnitType.NUMBER"
                          label="Longitude" 
                          >
                        </DistanceTextArea>
                      </v-col>
                      <template v-if="editedItem.metric == 'RACE_RESULT'">
                      <v-col cols="12" class="py-0">
                        <h3>Race Result</h3>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-0">
                        <v-select
                          v-if="seriesEvents && seriesEvents.length"
                          :items="$helpers.prefixSelectListWith(seriesEvents, event)"
                          v-model="editedItem.linked_event_id" 
                          item-value="id"
                          item-text="name"
                          label="Event (from series)" 
                          />
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-0">
                        <v-select
                          :items="otherRacesInEvent"
                          v-model="editedItem.linked_race_id" 
                          item-value="id"
                          item-text="name"
                          label="Race" 
                          />
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="py-0">
                        <v-switch
                          v-model="editedItem.incomplete_result" 
                          label="Allow incomplete results" 
                          persistent-hint
                          hint="When enabled, also partial results will show. Otherwise only completed results will show."
                          />
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="editedItem.transformation_expr" 
                          label="Transformation Expression" 
                          persistent-hint
                          hint="Advanced setting. Enter a custom transformation function."
                          />
                      </v-col>
                      </template>
                      <v-col v-if="editedItem.metric == 'COORDINATE'" cols="12" sm="6" md="6" class="py-0">
                        Use <a href="https://www.latlong.net/" target="_blank">this webpage</a> to quickly find the coordinate of any location.
                      </v-col>

                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fal fa-envelope</v-icon>
                      Email
                      <v-spacer/>
                      <span v-if="editedItem.send_email" class="shrink mr-4 green--text">ON</span>
                      <span v-if="!editedItem.send_email" class="shrink mr-4 red--text">OFF</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-alert type="info" outlined>All email customization options will soon move to the new <router-link :to="{name:'eventmanagerEmails', params: {id:event.id}}">Email Customization</router-link> screen.</v-alert>
                        <p>Enable the email option to have an automatic email send when this badge is earned. The email contains a Share option which helps to increase engagement of this challenge.</p>
                        <v-btn v-if="editedItem.send_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendTestEmail">Send test email</v-btn>
                        <v-switch v-model="editedItem.send_email" class="mt-0" label="Send email" hint="Enable to send a (customizable) email whenever this badge is earned with social share options." persistent-hint></v-switch>
                        <MarkdownEditor v-if="editedItem.send_email" v-model="editedItem.email_template" :eventId="event.id" label="Badge reward email" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
<!--                   <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-icon color="gray" class="shrink mr-4">fadl fa fa-info-circle</v-icon>
                      General
                      <v-spacer/>
                      <v-switch label="test" hide-details class="ma-0 mr-4"/>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </v-expansion-panel-content>
                  </v-expansion-panel>
 -->                </v-expansion-panels>                
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                  <v-btn color="secondary"  :loading="$store.getters.isLoading" @click="saveDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showDesignDialog" max-width="650px"  @input="v => v || closeDesignDialog()">
              <template v-slot:activator="{ on }">
              </template>
              <v-card>
                <v-card-title class="headline">Badge designer</v-card-title>
                <v-card-text>
                  <v-form v-model="valid" lazy-validation ref="designForm">
                    <v-row>
                      <v-col cols="6">
                        <h3>Options</h3>
                        <v-text-field 
                          v-model="editedItem.color" 
                          @change="updatePreviewImage" 
                          label="Color (code or name)" 
                        >
                          <template v-slot:append>
                            <v-menu v-model="showColorPickerMenu" right :close-on-content-click="false" @input="v => v || updatePreviewImage()">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyle" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="editedItem.color" @change="updatePreviewImage" show-swatches mode="hexa" flat />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>                        
                        </v-text-field>
                        <v-file-input filled prepend-icon="fa fa-camera" accept="image/*" :rules="uploadRules" label="Upload an image" v-model="uploadFile" v-on:change="uploadBadgeImage"></v-file-input>
                        <v-text-field v-if="false" v-model="editedItem.custom_img" @change="updatePreviewImage" label="Custom image (url)" hint="Enter the full url to a custom image, starting with 'https://'." persistent-hint ></v-text-field>
                        <v-switch
                          v-if="editedItem.custom_img"
                          v-model="editedItem.shape" 
                          label="Show image in round frame"
                          :false-value="null"
                          true-value="simple"
                          @change="updatePreviewImage" 
                          />
                        <v-text-field 
                          v-if="!editedItem.custom_img"
                          v-model="editedItem.img_text" 
                          @change="updatePreviewImage" 
                          label="Text on badge" 
                        >
                        </v-text-field>
                        <v-select
                          :items="siteData.badge_shapes"
                          v-if="!editedItem.custom_img"
                          v-model="editedItem.shape" 
                          @change="updatePreviewImage"
                          item-value="type"
                          item-text="text"
                          label="Shape"
                          class="mt-0 "
                        ></v-select>
                        <v-select
                          :items="siteData.badge_icons"
                          v-if="!editedItem.custom_img"
                          v-model="editedItem.icon" 
                          @change="updatePreviewImage"
                          item-value="type"
                          item-text="text"
                          label="Icon"
                          class="mt-0 "
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <img :src="previewUrl" style="max-width:100%;height:220px;display:block;"/>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDesignDialog">Cancel</v-btn>
                  <v-btn color="secondary" :loading="$store.getters.isLoading" @click="saveDesignDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }} 
            <v-icon v-if="item.include_challenge" small color="green" title="This badge is part of the challenge goal">fa-asterisk</v-icon> 
            <v-icon v-if="item.send_email" small color="gray" title="This badge triggers an email">fadl fal fa-envelope</v-icon> 
            <v-icon v-if="item.audio_msg" small color="gray" :title="`This badge triggers the audio message: '${item.audio_msg}'.`">fadl fal fa-volume</v-icon> 
          </div>
          <div v-if="item.after_badge_id" class="text-muted">Only after: {{ (badges.find(x => x.id == item.after_badge_id) || {}).name }}</div>
        </template>
        <template v-slot:item.img="{ item }">
          <img :src="item.img" style="width:32px;" @click="openDesignDialog(item)"/>
        </template>
        <template v-slot:item.active="{ item }">
          {{ item.active ? "active" : "INACTIVE" }}
          <span v-if="item.from || item.till">({{ item.from | localDate('L', /* inOriginalTimeZone */ true) }} - {{ item.till | localDate('L', /* inOriginalTimeZone */ true) }})</span>
          <span v-else-if="item.from_relative_days != null && item.till_relative_days != null && item.from_relative_days+1 == item.till_relative_days">(on day {{ item.from_relative_days + 1 }})</span>
          <span v-else-if="item.from_relative_days || item.till_relative_days">(start of day {{ item.from_relative_days + 1 }} - end of day {{ item.till_relative_days  }})</span>
        </template>
        <template v-slot:item.metric="{ item }">
          {{ item.metric | titleize }}
          <span v-if="item.metric == 'BEST_ACTIVITY'" class="">
            ({{ (item.activity_field || 'Distance') | titleize }})
          </span>
          <div v-if="item.metric == 'RACE_RESULT' && item.linked_event_id != event.id" class="text-muted">
            (cross event)
          </div>

          <v-chip v-if="item.bonus" color="primary" outlined>
            + {{ $helpers.getGoalDistanceForDisplay($options, race, item.bonus, event ) }}
          </v-chip>
        </template>
        <template v-slot:item.value="{ item }">
          <span v-if="item.metric == 'ACTIVITY' || item.metric == 'BEST_ACTIVITY'" class="">
            <span v-if="item.activity_type">{{ (item.activity_type || 'Any sport') | titleize }}</span>
            <span v-if="item.activity_distance">{{ item.activity_distance | distance(event.unit) }}</span>
            <span v-if="item.activity_time">{{ item.activity_time | duration }}</span>
          </span>
          <span v-else-if="item.metric == 'AGGREGATION'">
            {{ item.activity_aggr | titleize }} of {{ item.activity_field | titleize }} 
          </span>
          <span v-else-if="item.metric == 'COORDINATE'">
            Radius: {{ item.value }} m
          </span>
          <span v-else-if="item.metric == 'CONSECUTIVE_DAYS'">
            {{ item.value }} day(s)
          </span>
          <span v-else-if="item.metric == 'CONSECUTIVE_WEEKS'">
            {{ item.value }} week(s)
          </span>
          <span v-else-if="item.metric == 'PHOTO'" class="text-muted">
            n/a
          </span>
          <span v-else-if="item.metric == 'DURATION'">
            {{ item.value / 3600 }} hour(s)
          </span>
          <span v-else-if="item.metric == 'MANUAL_CLAIM'">
            {{ item.value }} pts
          </span>
          <span v-else-if="item.metric == 'SCORE' && race.scoring == 'TIME'">
            {{ (item.value / 3600).toFixed(2) }} hour(s)
          </span>
          <span v-else-if="item.metric == 'RACE_RESULT'">
            {{ item.value | distance(event.unit) }}
          </span>
          <span v-else>
            {{ item.value }} {{ badgeUnitTypeDiplay(item.unit) }}
          </span>
          <div v-if="item.metric == 'CONSECUTIVE_DAYS' || item.metric == 'CONSECUTIVE_WEEKS'" class="text-muted">
            <span v-if="item.activity_steps">
              {{ item.activity_steps }} steps
            </span>            
            <span v-if="item.activity_custom_min">
              {{ item.activity_custom_min }} {{race.custom}}
            </span>            
            <span v-if="item.activity_time">
              {{ item.activity_time | duration }}
            </span>            
          </div>
          <v-chip v-if="item.collective" color="primary" outlined x-small>
            Collective
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="eventUtil.hasRole('FULL_ACCESS')" :disabled="readonly" small title="Edit" class="mr-4" @click="openDialog(item)">
            fa-pencil-alt
          </v-icon>
          <v-icon v-if="eventUtil.hasRole('FULL_ACCESS')" :disabled="readonly" small title="Design" class="mr-4" @click="openDesignDialog(item)">
            fa-fill-drip
          </v-icon>
          <v-icon v-if="eventUtil.hasRole('FULL_ACCESS') && race.route" :disabled="readonly" small title="Add Along Course" class="mr-4" @click="openBadgeAlongCourse(item)">
            fa-route
          </v-icon>
          <v-icon v-if="eventUtil.hasRole('FULL_ACCESS')" :disabled="readonly" small title="Delete" class="mr-4" @click="deleteBadge(item)">
            fa-trash
          </v-icon>
          <v-icon v-if="eventUtil.hasRole('FULL_ACCESS') && item.collective" :disabled="readonly" small title="Send" class="mr-4" @click="rewardCollectiveBadge(item)">
            fa-trophy
          </v-icon>
          <v-icon v-if="eventUtil.hasRole('FULL_ACCESS') && item.metric === 'MANUAL_CLAIM'" :disabled="readonly" small title="View QR" class="me-4" @click="showClaimQr(item)">
            fa-qrcode
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No badges created yet, why not create one now?</i>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="emailSendSnackbar" top color="success" :timeout="6000">We've send a test email, it might take a few minutes to arrive.</v-snackbar>
    <RaceAddBadgeAlongCourseDialog ref="badgeAlongCourseDialog" :event="event" :race="race" @save="badgeCreatedAlongCourse" />

    <v-dialog v-if="claimDeeplinkUrl" v-model="showQrCode" max-width="400px">
      <v-card>
        <v-card-title>Claim QR</v-card-title>
        <v-card-text>
          <v-text-field readonly v-model="claimDeeplinkUrl"/>
          <v-btn outlined block class="shrink" @click="$helpers.copyToClipboard(claimDeeplinkUrl, $toast);showQrCode=false;">Copy URL to clipboard</v-btn>
        </v-card-text>
        <v-card-text>Print this QR code so participants can scan this QR code with their phone to claim this badge.</v-card-text>
        <QrCode :data="claimDeeplinkUrl" />
      </v-card>
    </v-dialog>      

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import RaceAddBadgeAlongCourseDialog from "@/components/RaceAddBadgeAlongCourseDialog";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import MarkdownEditor from "@/components/MarkdownEditor";
import QrCode from '@/components/generic/QrCode.vue'
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import DurationTextArea from "@/components/DurationTextArea";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Badges",
  components: {
    RaceAddBadgeAlongCourseDialog,
    DateWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    MarkdownEditor,
    Header,
    RaceSelector,
    QrCode,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      race: null,
      badges: [],
      showDialog: false,
      copyFromDialog: false,
      initialPanel: 0,
      copyFromRaceId: null,
      showAddAchievementDialog: false,
      showDesignDialog: false,
      showColorPickerMenu: false,
      emailSendSnackbar: false,
      previewUrl: null,
      uploadFile: null,
      valid: false,
      showQrCode: false,
      claimDeeplinkUrl: null,
      editedIndex: -1,
      editedItem: {active:true, color:'#F46524', metric: 'SCORE'},
      defaultItem: {active:true, color:'#F46524', metric: 'SCORE'},
      siteData: siteData,
      nameRules: [
        v => !!v || "Please enter the name",
      ],
      selectRules: [
        v => !!v || "Please select an item",
      ],
      uploadRules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB.',
      ],
      headers: [
        { text: 'Image', align: 'start', sortable: false, value: 'img',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Active', value: 'active', sortable: true },
        { text: 'Metric', value: 'metric', sortable: true },
        { text: 'Value', value: 'value', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id, /*includeSeries:*/ true)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.seriesEvents = this.event.series_events;
      this.badges = (await eventManagerService.getBadges(id, raceId)).data.data;
      this.editedItem.linked_event_id = this.event.id;
      this.defaultItem.linked_event_id = this.event.id;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    
    openDialog(item) {
      this.editedIndex = this.badges.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDialog = true
    },

    async openDesignDialog(item) {
      this.editedIndex = this.badges.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDesignDialog = true;
      await this.updatePreviewImage();
    },

    async openBadgeAlongCourse(item) {
      this.$refs.badgeAlongCourseDialog.open(item);
    },
    async showBadgeAlongCourseDialog() {
      // reload first
      this.$refs.badgeAlongCourseDialog.open();
    },
    async badgeCreatedAlongCourse(badge) {
      console.log('Added badge along course', badge);
      if (badge.id) {
        // Existing badge is updated, save
        this.editedItem = badge;
        await this.saveBadge();
      }
      else {
        // New badge, allow completion of data
        await this.getProfile();
        this.editedIndex = -1;
        this.editedItem = badge;
        this.showDialog = true;
      }
    },

    async mergeClaimBadges(badges) {
      console.log('MERGE CLAIM badges', badges);
      // todo: merge and save
    },

    async uploadBadgeImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadBadgeImage(this.event.id, this.race.id, this.editedItem.id, formData);
        if (response.data.status == "OK") {
          this.editedItem.custom_img = response.data.msg;
          this.updatePreviewImage();
        }
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
      }
    },

    async uploadBadgeDetailsImage() {
      if (this.uploadFile) {
        let formData = new FormData()
        formData.append("file", this.uploadFile, this.uploadFile.name);
        const response = await eventManagerService.uploadBadgeDetailsImage(this.event.id, this.race.id, this.editedItem.id, formData);
        this.$helpers.toastResponse(this, response.data, 'Image is uploaded successful!');
        if (response.data.status == "OK") {
          this.editedItem.details_img = response.data.msg;
        }
      }
    },

    async rewardCollectiveBadge(item) {
      if (confirm('Do you want to reward this collective badge? When email is enabled for this badge, this will email all participants on the leaderboard.')) {
        const response = await eventManagerService.rewardCollectiveBadge(this.event.id, this.race.id, item.id);
        this.$helpers.toastResponse(this, response.data, 'Badge has been rewarded.');
      }
    },

    async showClaimQr(item) {
      const response = await eventManagerService.getBadgeClaimLink(this.event.id, this.race.id, item.id);
      this.claimDeeplinkUrl = response.data.msg;
      // test: this.claimDeeplinkUrl = "https://corporatefitness.app/r/a/app.corporatefitness/koens-example-steptember-challenge/claim?raceId=total-steps&badgeId=bonus";
      this.showQrCode = true;
    },

    async updatePreviewImage() {
      if (this.editedItem.color.toString().match(/#[a-zA-Z0-9]{8}/)) {
        // color picker swatches add alpha channel, strip that away again
        this.editedItem.color = value.substr(0, 7);
      }
      this.previewUrl = null;
      const response = await eventManagerService.previewBadge(this.event.id, this.race.id, this.editedItem);
      if (response.data.status == "OK") {
        this.previewUrl = response.data.msg;
      }
    },

    async deleteBadge (item) {
      if (confirm(`Are you sure you want to delete this badge ${item.name}?`)) {
        await eventManagerService.deleteBadge(this.event.id, this.race.id, item.id);
        await this.getProfile();
      }
    },

    async copyBadges() {
      if (this.copyFromRaceId) {
        const response = await eventManagerService.copyBadges(this.event.id, this.copyFromRaceId, this.race.id);
        if (response.data.status == "OK") {
          this.copyFromDialog = false;
          await this.getProfile();
        }
      }
    },

    async sendTestEmail (item) {
      // save first to include textual changes!
      await this.saveBadge();
      // now send the email
      const response = await eventManagerService.sendBadgeEmail(this.event.id, this.race.id, this.editedItem.id);
      if (response.data.status == "OK") {
        this.emailSendSnackbar = true;
      }
      else {

      }
    },

    closeDialog () {
      this.showAddAchievementDialog = false
      this.showDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.previewUrl = null;
      })
    },

    async saveAchievementDialog() {
      this.$refs.achievementForm.validate();
      // set hard-coded values for manual claim badges
      this.editedItem.metric = 'MANUAL_CLAIM';
      this.editedItem.activity_field = 'VALUE';
      this.editedItem.value = 1;
      this.editedItem.include_challenge = true;
      this.$nextTick(async () => {
        if (this.valid) {
          var success = await this.saveBadge();
          if (success) this.closeDialog();
        }
      });
    },

    async saveDialog() {
      this.$refs.badgeForm.validate();
      this.$nextTick(async () => {
        if (this.valid) {
          var success = await this.saveBadge();
          if (success) this.closeDialog();
        }
        else {
          console.log('Invalid form state', this.$refs.badgeForm.errorMessages);
        }
      });
    },
    async saveBadge() {
      console.log('Saving', this.editedItem);
      var response = await eventManagerService.putBadge(this.event.id, this.race.id, this.editedItem);
      if (response.data.status == 'OK') {
        this.$helpers.toastResponse(this, response.data, 'Badge saved successfully.')
        await this.getProfile();
        return true;
      }
      return false;
    },

    closeDesignDialog () {
      this.showDesignDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.previewUrl = null;
      })
    },

    async saveDesignDialog() {
      await this.saveBadge();
      this.closeDesignDialog();
    },

    badgeUnitTypeDiplay(badgeUnitType) {
      return badgeUnitType == 'PERCENTAGE' ? '%' : this.unitTypeDiplay;
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    readonly() {
      return this.race && this.race.team && this.race.badge_scoring =='MANUAL_CLAIM';
    },
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Badges', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    otherRacesInEvent(){
      if (this.editedItem && this.editedItem.linked_event_id != null && this.editedItem.linked_event_id != this.event.id && this.seriesEvents) {
        return this.seriesEvents.find(x => x.id == this.editedItem.linked_event_id).races;
      }
      return this.event.races.filter(x => x.id != this.race.id);
    },
    otherBadgesInRace(){
      return [{id: null, name: '- none -'}, ...this.badges.filter(x => x.id != this.editedItem.id)];
    },
    activitiesWithDefault(){
      var data = siteData.activities;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    providersWithDefault(){
      var data = siteData.providers;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    unitTypeDiplay() {
      if (this.race.scoring == 'CUSTOM') {
        return this.race.custom || 'Points';
      }
      if (this.race.scoring == 'STEPS') {
        return 'Steps';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    showValue() {
      if (this.editedItem.metric == 'MANUAL_CLAIM' && this.race && this.race.scoring == 'BADGES') {
        return true;
      }
      if (this.editedItem && ['ACTIVITY', 'BEST_ACTIVITY', 'AGGREGATION', 'MANUAL_CLAIM', 'RACE_RESULT', 'PHOTO'].includes(this.editedItem.metric)) {
        return false;
      }
      return true;
    },
    showMetricUnit() {
      return this.editedItem && ['DISTANCE', 'ELEVATION', 'ELEVATION_LOSS', 'SCORE'].includes(this.editedItem.metric);
    },
    valueLabel() {
      if (!this.editedItem) {
        return null;
      }
      if (this.editedItem.metric == 'MANUAL_CLAIM') {
        return 'Points';
      }
      if (this.editedItem.metric == 'CONSECUTIVE_DAYS') {
        return 'Day(s)';
      }
      if (this.editedItem.metric == 'CONSECUTIVE_WEEKS') {
        return 'Week(s)';
      }
      if (this.editedItem.metric == 'DURATION') {
        return 'Hours';
      }
      if (this.editedItem.metric == 'COORDINATE') {
        return `Radius (in meter)`;
      }
      if (this.editedItem && this.editedItem.unit == 'FIXED') {
        if (this.editedItem.metric == 'DISTANCE' || (this.editedItem.metric == 'SCORE' && this.race.scoring == 'DISTANCE'))
          return `Distance (in ${this.unitTypeDiplay})`;
        else if (this.editedItem.metric == 'ELEVATION' || this.editedItem.metric == 'ELEVATION_LOSS' || (this.editedItem.metric == 'SCORE' && this.race.scoring == 'ELEVATION'))
          return `Elevation (in ${this.unitTypeDiplay})`;
        return `${this.unitTypeDiplay}`;
      }
      if (this.editedItem && this.editedItem.unit == 'PERCENTAGE') {
        return 'Percentage (0 - 100)';
      }
    },
    computedValue:{
      get () {
        return this.editedItem && this.editedItem.value ? this.editedItem.value : 0
      },
      set (value) {
        this.editedItem.value = value || 0 // coerce to null
      }
    },
    computedBonusValue:{
      get () {
        return this.editedItem && this.editedItem.bonus ? this.editedItem.bonus : 0
      },
      set (value) {
        this.editedItem.bonus = value || 0 // coerce to null
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Badge' : 'Edit Badge'
    },
    swatchStyle() {
      const { editedItem, showColorPickerMenu } = this
      return {
        backgroundColor: editedItem.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

